<template>
    <div class="containerDeviceDetails">
        <div class="content-box" v-show="!merchantFlag">
            <div class="title">商家名称</div><span class="content">{{isWaveShoppingMall ? '--' : merchantName}}</span>
        </div>
        <div class="content-box">
            <div class="title">门店信息</div><span class="content">{{isWaveShoppingMall ? '--' : groupName}}</span>
        </div>
        <div class="content-box">
            <div class="title">名称</div><span class="content">{{name}}</span>
        </div>
        <div class="content-box">
            <div class="title">设备型号</div><span class="content">{{typeStr}}</span>
        </div>
        <div class="content-box" v-show="!isSpringMachine">
            <div class="title">MAC</div><span class="content">{{mac}}</span>
        </div>
        <div class="content-box" v-show="isSpringMachine">
            <div class="title">芯片ID</div><span class="content">{{mac}}</span>
        </div>
        <div class="content-box">
            <div class="title">ICCID</div><span class="content">{{iccid}}</span>
        </div>
        <div class="content-box">
            <div class="title">IP</div><span class="content">{{ip}}</span>
        </div>
        <div class="content-box">
            <div class="title">固件版本</div><span class="content">{{version}}</span><span v-show="isAdmin" class="editClass" @click="clickUpgrade">升级</span>
        </div>
        <div class="content-box" v-if="containerTypeVal !== 2001 && containerTypeVal !== 2006">
            <div class="title">从柜固件版本</div><span class="content">{{subHwVersion}}</span>
        </div>
        <div class="content-box" v-if="containerType == 'L22'||containerType == 'L22B'||containerType == 'L22BD'||containerType == 'L22BX'||containerType == 'L25'&&containerTypeVal !== 2001&&containerTypeVal !== 2006">
            <div class="title">柜门（合计）</div><span class="content">{{totalNums}}个</span>
            <div class="title1">购物柜</div><span class="content">{{shoppingNums}}个</span>
            <div class="title1">存货柜</div><span class="content">{{stockNums}}个</span>
            <div class="title1" v-show="showBatteryCabinet">电池柜</div><span class="content"
                v-show="showBatteryCabinet">1个</span>
            <div class="editClass" @click="checkFun">查看</div>
        </div>
        <div class="content-box" v-if="containerType == 'L21BD'||containerType == 'L21BX'">
            <div class="title">柜门（合计）</div><span class="content">{{totalNums}}个</span>
            <div class="title1" v-if="shoppingNums>0">购物柜</div>
            <div class="title1" v-if="stockNums>0">存货柜</div>
        </div>
        <div class="content-box" v-if="containerType == 'L25A' || containerType == 'L25AB'">
            <div class="title">柜门（合计）</div><span class="content">{{totalNums}}个</span>
            <div class="editClass" @click="checkFun">查看</div>
        </div>
        <div class="content-box" v-if="containerTypeVal == 2001 || containerTypeVal == 2006">
            <div class="title">柜门（合计）</div><span class="content">{{L27shoppingNums}}个</span>
            <div class="title1">货位</div><span class="content">{{L27stockNums}}个</span>
            <div class="editClass" @click="checkL27Fun">查看</div>
        </div>
        <div class="content-box" v-if="isSpringMachine">
            <div class="title">货道（合计）</div><span class="content">{{totalNums}}个</span>
            <span style="color: #333333;margin-left: 20px;" v-for="(item,index) in freightTrackList" :key='index'>货道类型{{item.channelType}}：<span class="content">{{item.count}}</span>个</span>
        </div>
        <!-- <div class="content-box" v-if="containerType == 'L25'||containerType == 'L25A'">
            <div class="title">关联的主柜</div><span class="content">{{relationMainCabinet}}个</span>
            <div class="editClass" @click="checkMainCabinet">查看</div>
        </div> -->
        <div class="content-box">
            <div class="title">门店位置</div>
            <div class="locationContent">{{isWaveShoppingMall ? '--' : location}}</div>
        </div>
        <div class="content-box">
            <div class="title">货柜位置</div>
            <div class="locationContent">{{address}}</div>
        </div>
        <div class="content-box">
            <div class="title">货管员</div><span class="content">{{isWaveShoppingMall ? '--' : realName}}</span>
            <div v-if="!isWaveShoppingMall" class="el-icon-edit-outline editClass" @click="editFun">编辑</div>
        </div>
        <div class="content-box">
            <div class="title">货管员手机号</div><span class="content">{{isWaveShoppingMall ? '--' : phone}}</span>
        </div>
        <div class="content-box">
            <div class="title">绑定时间</div><span class="content">{{createdTime}}</span>
        </div>
        <div class="content-box">
            <div class="title">联网状态</div><span class="content">{{onlineState}}</span>
        </div>
        <div class="content-box" v-if="showBattery">
            <div class="title">电量</div><span class="content">{{batteryStr}}</span>
        </div>
        <div class="content-box" v-show="containerType != 'L25A'">
            <div class="title">货品状态</div><span class="content">{{isWaveShoppingMall ? '--' : goodsStateStr}}</span>
        </div>
        <div class="content-box">
            <div class="title">最近一次开启时间</div><span class="content">{{lastOpenDate}}</span>
        </div>
        <div class="content-box">
            <div class="title">启用状态</div>
            <el-switch style="display: inline-block" v-model="useState" :disabled="true" active-color="#67C23A"
                inactive-color="#F56C6C" active-text="启用" inactive-text="禁用">
            </el-switch>
        </div>
        <div style="display: flex;" v-if="containerType != 'L25A'">
            <div class="content-box" v-if="isShowShoppingCode" style="display: flex;">
                <div class="shoppingCodeTitle">{{codeName}}</div>
                <div v-if="isWaveShoppingMall" style="padding-left: 40px">--</div>
                <img v-else class="shoppingCode" :src="shoppingCode" alt="暂无二维码信息">
            </div>
            <div class="content-box" v-if="isShowAccessCode" style="display: flex;">
                <div :class="!isShowShoppingCode ? 'shoppingCodeTitle' : 'accessCodeTitle'">存货二维码</div>
                <div v-if="isWaveShoppingMall" style="padding-left: 40px">--</div>
                <img v-else class="shoppingCode" :src="stockCode" alt="暂无二维码信息">
            </div>
            <div class="content-box" v-if="isShowAccessCode" style="display: flex;">
                <div :class="!isShowShoppingCode ? 'shoppingCodeTitle' : 'accessCodeTitle'">取货二维码</div>
                <div v-if="isWaveShoppingMall" style="padding-left: 40px">--</div>
                <img v-else class="shoppingCode" :src="pickUpCode" alt="暂无二维码信息">
            </div>
        </div>

        <div class="content-box" style="display: flex;">
            <div class="containerPicTitle">货柜照片</div>
            <div style="width: 500px" v-for="(item,index) in picList" :key="index">
                <img class="containerPic" :src="item">
            </div>
        </div>
        <!-- 编辑安装位置 -->
        <div class="goodsControllerDialog">
            <el-dialog title="编辑" :visible.sync="dialogVisibleEdit" :close-on-click-modal="false">
                <div class="editBox">
                    <el-form ref="form" :model="form" :rules="rules">
                        <div class="editContent">
                            <div class="editTitle">设备MAC</div><span>{{mac}}</span>
                        </div>
                        <el-form-item label-width="120px" label="货管员姓名" prop="counterUserIdList">
                            <el-select style="width: 320px;display: inline-block;" filterable remote multiple
                                reserve-keyword :remote-method="remoteMethod"  v-model="form.counterUserIdList"
                                ref="select" placeholder="根据货管员姓名搜索" @change="onChangeSelect(form.counterUserIdList)"
                                @clear="handleClear()" clearable>
                                <el-option v-for="it in realNameList" :key="it.value" :label="it.label"
                                    :value="it.value">
                                    <span style="float: left">{{it.label}}</span>
                                    <span style="float: right;font-size: 13px">{{it.phone}}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label-width="120px" label="主货管员姓名" prop="masterCounterUser">
                            <el-select style="width: 320px;display: inline-block;" filterable remote  reserve-keyword
                                :remote-method="remoteMethod" v-model="form.masterCounterUser"
                                ref="select"  @change="onChange(form.masterCounterUser)"
                                @clear="handleClear()" clearable>
                                <el-option v-for="it in form.counterUserIdList1" :key="it.value" :label="it.label"
                                    :value="it.value">
                                    <span style="float: left">{{it.label}}</span>
                                    <span style="float: right;font-size: 13px">{{it.phone}}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submit()">提交</el-button>
                </span>
            </el-dialog>
        </div>

        <!-- 查看L27的柜门信息 -->
        <el-dialog title="货柜柜门信息" :visible.sync="dialogVisibleCheckL27" :close-on-click-modal="false" width="50%"
            top="20vh">
            <div class="deviceDetails-check">
                <div class="deviceDetails-check-title">{{name}}</div>
                <div class="deviceDetails-check-text">
                    <div style="display: inline-block;width:257px;margin-right:20px;">{{location}}</div>
                    <template>
                        <span>柜门：<span style="color: #30BAC1;padding:0;">{{L27shoppingNums}}</span></span>
                        <span>货位：<span style="color: #30BAC1;padding:0;">{{L27stockNums}}</span></span>
                    </template>
                </div>
                <div class="deviceDetails-table">
                    <el-table :data="tableDataLt" height="400" style="width: 100%">
                        <el-table-column prop="name" align="center" label="柜门-货位">
                        </el-table-column>
                        <el-table-column prop="typeStr" align="center" label="货柜用途">
                        </el-table-column>
                        <el-table-column prop="goodsStateStr" align="center" label="柜门状态">
                        </el-table-column>
                    </el-table>
                    <el-table :data="tableDataRt" height="400" style="width: 100%" v-if="tableDataRt.length>0">
                        <el-table-column prop="name" align="center" label="柜门-货位">
                        </el-table-column>
                        <el-table-column prop="typeStr" align="center" label="货柜用途">
                        </el-table-column>
                        <el-table-column prop="goodsStateStr" align="center" label="柜门状态">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeL27Check()">关闭</el-button>
            </span>
        </el-dialog>

        <!-- 其他柜子 -->
        <el-dialog title="货柜柜门信息" :visible.sync="dialogVisibleCheck" :close-on-click-modal="false" width="50%"
            top="20vh">
            <div class="deviceDetails-check">
                <div class="deviceDetails-check-title">{{name}}</div>
                <div class="deviceDetails-check-text">
                    <div style="display: inline-block;width:257px;margin-right:20px;">{{location}}</div>
                    <template v-if="containerTypeVal==19">
                        <span>换电柜：<span style="color: #30BAC1;padding:0;">{{shoppingNums}}</span></span>
                    </template>
                    <template v-else>
                        <span>购物柜：<span style="color: #30BAC1;padding:0;">{{shoppingNums}}</span></span>
                        <span>存货柜：<span style="color: #30BAC1;padding:0;">{{stockNums}}</span></span>
                    </template>
                    
                </div>
                <div class="deviceDetails-table">
                    <el-table :data="tableData" height="400" style="width: 100%">
                        <el-table-column prop="name" align="center" label="柜门编号">
                        </el-table-column>
                        <el-table-column prop="typeStr" align="center" label="货柜用途">
                        </el-table-column>
                        <el-table-column prop="goodsStateStr" align="center" label="柜门状态">
                        </el-table-column>
                        <el-table-column prop="batteryPowerStr" align="center" label="柜门电量">
                        </el-table-column>
                        <el-table-column prop="serialType" align="center" label="柜门型号">
                        </el-table-column>
                        <el-table-column prop="doorType" align="center" label="柜门类型">
                        </el-table-column>
                        <el-table-column prop="doorMacStr" align="center" label="从柜Mac">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeCheck()">关闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="查看关联的主柜" :visible.sync="showContainerModel" :close-on-click-modal="false">
            <el-table :data="containerModelList" height="400" style="width: 100%">
                <el-table-column prop="containerModel" align="center" label="货柜型号">
                </el-table-column>
                <el-table-column prop="containerNumber" align="center" label="货柜编号">
                </el-table-column>
                <el-table-column prop="areaNumber" align="center" label="区域编码">
                </el-table-column>
                <el-table-column prop="containerLocation" align="center" label="安装位置">
                </el-table-column>
                <el-table-column prop="containerState" align="center" label="货柜状态">
                </el-table-column>
                <el-table-column prop="correlationTime" align="center" label="关联时间">
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeContainerModel()">关闭</el-button>
            </span>
        </el-dialog>

        <!-- 升级固件 -->
        <el-dialog title="" width="500px" top="30vh" :visible.sync="dialogVisibleUpdate" @close="closeFun()"
            :close-on-click-modal="false">
            <div class="dialog_content">
                <div>执行升级大约需要2分钟，请不要断电</div>
                <div style="padding-top:20px;">
                    <el-input style="width:200px;" v-model.trim="passwordVal" placeholder="请输入升级密码"></el-input>

                </div>
            </div>
            <div class="dialog_bottom">
                <el-button @click="closeFun">取消</el-button>
                <el-button type="primary" @click="sureBtn">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    const password = 'wave@abc123.'
    let phoneVali = (rule, value, callback) => {
        if (!value || (value && value.length != 11)) {
            callback(
                new Error("请输入正确的手机号码")
            );
        } else {
            callback();
        }

    };
    let validLocation = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback(new Error("请输入安装位置"));
        } else {
            callback();
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                showContainerModel: false,
                relationMainCabinet: 1, //关联主柜个数
                tableDataLt: [], //左边表格数据
                tableDataRt: [], //右边表格数据,
                tableData: [],
                // containerModelList: [{
                //     containerModel: 'WF-L25',
                //     containerNumber: 'S45F51G5FG',
                //     areaNumber: 'B1',
                //     containerLocation: '宝安西乡航程工业区B1栋1楼东侧',
                //     containerState: '在线',
                //     correlationTime: '2022-05-12 15:12:10',
                // }, {
                //     containerModel: 'WF-L25B',
                //     containerNumber: 'S45F51G5FF',
                //     areaNumber: 'B2',
                //     containerLocation: '宝安西乡航程工业区B1栋1楼东侧',
                //     containerState: '在线',
                //     correlationTime: '2022-05-20 15:12:10',
                // }],
                containerModelList: [],
                containerType: "", //货柜用途
                containerTypeVal: '',
                groupName: "",
                merchantName: "",
                merchantId: "",
                counterUserIdList1:"",
                merchantFlag: false,
                state: false,
                dialogVisibleEdit: false,
                dialogVisibleCheck: false,
                dialogVisibleCheckL27: false,
                realName: "",
                goodsStateStr: "",
                batteryStr: "",
                showBattery: false,
                name: "",
                type: "",
                typeStr: "",
                mac: "",
                iccid: "",
                ip: "",
                version: "",
                subHwVersion: "",
                location: "",
                address: "",
                phone: "",
                createdTime: "",
                onlineState: "", //0,离线,1,在线
                lastOpenDate: "",
                useState: "", //0启用1禁用
                shoppingCode: "",
                stockCode: "",
                pickUpCode: "",
                isShowAccessCode: false, //显示存货二维码和取货二维码
                isShowShoppingCode: false, //显示购物二维码
                showBatteryCabinet: false,
                picList: [],
                totalNums: "",
                stockNums: "",
                shoppingNums: "",
                L27shoppingNums: "",
                L27stockNums: "",
                form: {
                    counterUserIdList: [],
                    masterCounterUser: '',
                    counterUserIdList1: [],
                },
                
                rules: {
                    counterUserIdList: [{
                        required: true,
                        message: "请选择货管员",
                        trigger: ['change', 'blur']
                    }],
                    masterCounterUser: [{required: true,
                        message: "请选择货管员",
                        trigger: ['change', 'blur']}]
                },
                pageCurrent: 1,
                pageSize: 99,
                pageNum: 1,
                loading: false,
                realNameList: [],
                realNameId:'',
                counterUserIdList: [],
                masterCounterUser:"",
                passwordVal: '',
                dialogVisibleUpdate: false,
                upgradeDto: {},
                isAdmin: false,
                isSpringMachine: false,
                freightTrackList: [],
                codeName: '货柜购物二维码',
                bindPlatformType: 1,
                isWaveShoppingMall: false,
            };
        },
        created() {},
        activated() {
            console.log("deviceDetails activated!!");
            this.bindPlatformType = this.cache.get('bindPlatformTypeVal') || 1
            this.isWaveShoppingMall = this.bindPlatformType == 2
            // 之后再这里写获取设备详情的代码  发送请求并赋值
            this.getDeviceDetail();
            this.containerType = this.$route.params.type;
            this.containerTypeVal = this.$route.query.type;
            if(this.containerTypeVal == 19) {
                this.codeName = '换电柜二维码'
            }else {
                this.codeName = '货柜购物二维码'
            }
            this.isShowAccessCode = false;
            this.isShowShoppingCode = false;
            this.showBatteryCabinet = false;
            this.merchantFlag = this.getLoginType('loginType');
            this.isAdmin = this.getAdministratorFlage();
            if(this.$route.query.type && [20,2014].indexOf(Number(this.$route.query.type)) != -1) {
                this.isSpringMachine = true; 
            }else {
                this.isSpringMachine = false;
            }
            this.getTypeList();
            this.tableDataLt = [] //L27左边表格数据
            this.tableDataRt = [] //L27右边表格数据,
            this.tableData = []
        },
        mounted() {},
        deactivated() {
            this.dialogVisibleUpdate = false;
            this.dialogVisibleCheck = false;
            this.dialogVisibleCheckL27 = false
        },
        methods: {
            editFun() {
                this.dialogVisibleEdit = true;
                this.pageSize = 99;
                this.query = '';
                this.$nextTick(() => {
                    this.$refs.select.$refs.scrollbar.$refs.wrap.addEventListener('scroll', this.scolling)

                })
                this.getGoodsControllerList();
            },
            submit() {
                let url = "mall-service/v1/counter/auth/update-counter-user";
                let dto = {
                    counterId: this.$route.query.id,
                    masterUserId: this.form.masterCounterUser,
                    counterUserIdList: this.form.counterUserIdList,
                    
                };
                
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.post(url, dto).then(res => {
                            this.dialogVisibleEdit = false;
                            this.$message({
                                showClose: true,
                                message: '编辑成功！',
                                type: "success"
                            });
                            this.getDeviceDetail();

                        })
                    } else {
                        return false;

                    }
                })
            },
            getDeviceDetail() {
                var id = this.$route.query.id;
                let dto = {
                    id: this.$route.query.id,
                    type: this.$route.query.type
                };
                this.upgradeDto = {};
                this.post("mall-service/counter/detail", dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.data.groupName && res.data.data.region) {
                        this.groupName = res.data.data.groupName + '-' + res.data.data.region + res.data.data
                            .regionNumber;
                    } else {
                        this.groupName = '--';
                    }
                    // upgradeDto--升级用的入参
                    this.upgradeDto = {
                        mac: res.data.data.mac,
                        id: res.data.data.id,
                        counterType: res.data.data.type,
                        size: res.data.data.size,
                    };
                    this.merchantName = res.data.data.merchantName ? res.data.data.merchantName : '--';
                    this.merchantId = res.data.data.merchantId;
                    this.name = res.data.data.name;
                    this.type = res.data.data.type;
                    this.mac = res.data.data.mac;
                    this.iccid = res.data.data.iccid ? res.data.data.iccid : '--';
                    this.ip = res.data.data.ip ? res.data.data.ip : '--';
                    this.version = res.data.data.version ? res.data.data.version : '--';
                    this.subHwVersion = res.data.data.subHwVersion ? res.data.data.subHwVersion : '--'
                    this.location = res.data.data.location;
                    this.address = res.data.data.address;
                    this.phone = res.data.data.masterCounterUser.phone;
                    this.createdTime = res.data.data.createdTime;
                    this.onlineState = ["离线", "在线"][res.data.data.onlineState];
                    this.lastOpenDate = res.data.data.lastOpenDate ? res.data.data.lastOpenDate : '--';
                    if (res.data.data.useState == 0) { 
                        this.useState = true;
                    } else if (res.data.data.useState == 1) {
                        this.useState = false;
                    }
                    this.realName = res.data.data.masterCounterUser.realName;
                    this.realNameId = res.data.data.masterCounterUser.id;
                    console.log(res.data.data.masterCounterUser,111111);
                   this.counterUserIdList = res.data.data.counterUserIdList ? res.data.data.counterUserIdList : [];
                   this.masterCounterUser = res.data.data.masterCounterUser ? res.data.data.masterCounterUser : [];
                    this.goodsStateStr = res.data.data.shoppingNums > 0 ? ['缺货', "满货", "未满"][res.data.data.goodsState] : '--';
                    this.picList = res.data.data.picList;
                    this.totalNums = res.data.data.totalNums;
                    this.stockNums = res.data.data.stockNums;
                    if(res.data.data.doorList){
                        this.L27stockNums = res.data.data.doorList.length;
                    }
                    this.L27shoppingNums = res.data.data.doorTotalNum;
                    this.shoppingNums = res.data.data.shoppingNums;
                    // this.typeStr = ['WF-L2K','WF-L21','WF-L22','WF-L21B','WF-L22B','WF-L21BD','WF-L21BX','WF-L22BD','WF-L22BX'][res.data.data.type - 1]
                    let typeList = [];
                    let codeType = '';
                    if (this.$route.query.type == 10) {
                        // 因为L25B和L25共用页面，this.$route.params.type相同，所以codeType做区分
                        codeType = 'L25B';
                    } else if (this.$route.query.type == 11) {
                        codeType = 'L25C';
                    } else if (this.$route.query.type == 12) {
                        codeType = 'L25AF';
                    } else if (this.$route.query.type == 13) {
                        codeType = 'L25CF';
                    } else if (this.$route.query.type == 14) {
                        codeType = 'L25AK';
                    } else if (this.$route.query.type == 15) {
                        codeType = 'L25CK';
                    } else if (this.$route.query.type == 16) {
                        codeType = 'L25BK';
                    } else if (this.$route.query.type == 2007) {
                        codeType = 'L25MK';
                    } else if (this.$route.query.type == 2013) {
                        codeType = 'L25BMK';
                    } else if (this.$route.query.type == 2009) {
                        codeType = 'L25-6';
                    } else if (this.$route.query.type == 2010) {
                        codeType = 'L25-12';
                    } else if (this.$route.query.type == 2011) {
                        codeType = 'L25-18';
                    } else if (this.$route.query.type == 2012) {
                        codeType = 'L25-24';
                    } else if (this.$route.query.type == 18) {
                        codeType = 'L25D';
                    } else if (this.$route.query.type == 2001) {
                        codeType = 'L27';
                    } else if (this.$route.query.type == 2006) {
                        codeType = 'L27N';
                    } else if (this.$route.query.type == 2004) {
                        codeType = 'L25E';
                    }else if (this.$route.query.type == 2008) {
                        codeType = 'L25E85';
                    } else if (this.$route.query.type == 2005) {
                        codeType = 'L25F';
                    } else if (this.$route.query.type == 2016) {
                        codeType = 'L25AM';
                    }else {
                        codeType = this.$route.params.type;
                    }
                    this.typeStr = 'WF-' + codeType;
                    if (['L22BD', 'L22BX', 'L25', 'L25A','L25AB'].indexOf(codeType) != -1) {
                        this.showBattery = false;
                    } else if (['L21BD', 'L21BX'].indexOf(codeType) != -1) {
                        this.showBattery = true;
                        if (res.data.data.doorList && res.data.data.doorList.length > 0) {
                            this.batteryStr = (res.data.data.doorList[0].batteryPower === null) ? '--' : res
                                .data.data.doorList[0].batteryPower + '%';
                        }
                    }
                    if (res.data.data.doorList) {
                        // L22 货柜
                        //货柜柜门信息
                        let midLength = Math.ceil(res.data.data.doorList.length / 2);
                        this.tableDataLt = [];
                        this.tableDataRt = [];
                        this.tableData = []
                        res.data.data.doorList.forEach((item, index) => {
                            if (this.containerTypeVal == 9 || this.containerTypeVal == 11) {
                                if (res.data.data.onlineState == 0 && item.type == 1) {
                                    item.goodsStateStr = "离线";
                                } else if (res.data.data.onlineState == 1 && item.type == 1) {
                                    if (item.rowCounterUserName) {
                                        item.goodsStateStr = '占用';
                                    } else {
                                        item.goodsStateStr = '可用';
                                    }
                                }
                            } else {
                                if (res.data.data.onlineState == 0 && item.type == 1) {
                                    item.goodsStateStr = "离线";
                                } else if (res.data.data.onlineState == 1 && item.type == 1) {
                                    if(this.containerTypeVal == 2016) {
                                        if(item.workable == 1) {
                                            item.goodsStateStr = ['可用', '占用'][item.goodsState];
                                        }else {
                                            item.goodsStateStr = '禁用';
                                        }
                                    }else {
                                        item.goodsStateStr = ['正常', '占用'][item.goodsState];
                                    }
                                }
                            }
                            if (item.type == 2) {
                                item.goodsStateStr = ['缺货', '满货', '未满'][item.goodsState];
                            }
                            if(this.containerTypeVal == 19) {
                                if(item.type == 2) {
                                    item.typeStr = '换电柜';
                                }
                            }else {
                                item.typeStr = ['存货柜', '购物柜', '电池柜'][item.type - 1]; //1-存货柜 2-购物柜
                            }
                            
                            if (cabinetUtil.hasSizeProperty(this.type) && item.size) {
                                item.typeStr = item.typeStr + '（' + ['大', '小'][item.size - 1] + '）';
                            }
                            item.batteryPowerStr = (item.batteryPower === null) ? '--' : item.batteryPower + '%';
                            item.serialType = item.serialType ? item.serialType : codeType;
                            if(item.doorMac){
                                item.doorType = '蓝牙柜';
                                item.doorMacStr = item.doorMac
                            }else{
                                item.doorType = '有线柜';
                                item.doorMacStr = '--'
                            }
                            typeList.push(item.type);
                            if (index < midLength) {
                                this.tableDataLt.push(item)
                            } else {
                                this.tableDataRt.push(item)
                            }
                            this.tableData.push(item)
                        });
                        // this.tableData = res.data.data.doorList;
                        //this.tableDataLt=res.data.data.doorList[]
                        if (this.containerTypeVal == 8) {
                            res.data.data.doorList.find(item => {
                                if (item.type == 3) {
                                    return this.showBatteryCabinet = true;
                                }
                            })
                        }
                        // 有存货柜
                        if (typeList.indexOf(1) != -1) {
                            this.isShowAccessCode = true;
                            // 存货二维码
                            this.post("/tenant-service/utils/generateQrCode", {
                                text: res.data.data.qrCode + "&type=stock"
                            }, {
                                isShowLoading: true
                            }).then(data => {
                                if (data) {
                                    const base64ImgUrl =
                                        "data:image/png;base64," + data.result;
                                    this.stockCode = base64ImgUrl;
                                }
                            });
                            // 取货二维码
                            this.post("/tenant-service/utils/generateQrCode", {
                                text: res.data.data.qrCode + "&type=pickUp"
                            }, {
                                isShowLoading: true
                            }).then(data => {
                                if (data) {
                                    const base64ImgUrl =
                                        "data:image/png;base64," + data.result;
                                    this.pickUpCode = base64ImgUrl;
                                }
                            });
                        }
                        // 有取货柜
                        if (typeList.indexOf(2) != -1) {
                            this.isShowShoppingCode = true;
                            // 购物二维码
                            this.post("/tenant-service/utils/generateQrCode", {
                                text: res.data.data.qrCode + "&type=" + codeType
                            }, {
                                isShowLoading: true
                            }).then(data => {
                                if (data) {
                                    const base64ImgUrl =
                                        "data:image/png;base64," + data.result;
                                    this.shoppingCode = base64ImgUrl;
                                }
                            });
                        }
                    } else {
                        // L2K和L21只有购物二维码
                        this.isShowShoppingCode = true;
                        // 购物二维码
                        this.post("/tenant-service/utils/generateQrCode", {
                            text: res.data.data.qrCode + "&type=" + codeType
                        }, {
                            isShowLoading: true
                        }).then(data => {
                            if (data) {
                                const base64ImgUrl =
                                    "data:image/png;base64," + data.result;
                                this.shoppingCode = base64ImgUrl;
                            }
                        });
                    }



                });

            },
            // 查看货柜柜门信息
            checkFun() {
                this.dialogVisibleCheck = true;
            },
            closeCheck() {
                this.dialogVisibleCheck = false;
            },
            checkL27Fun() {
                this.dialogVisibleCheckL27 = true;
            },
            closeL27Check() {
                this.dialogVisibleCheckL27 = false;
            },
            checkMainCabinet() {
                this.showContainerModel = true;
            },
            closeContainerModel() {
                this.showContainerModel = false;
            },
            handleClear() {},
            onChangeSelect(counterUserIdList) {
                //   选择的房管员ID  value
                    let counterUserIdListValue1 = []
                    this.realNameList.forEach(item => {
                        counterUserIdList.forEach(val => {
                            if (item.value == val && item.type != 1) {
                                counterUserIdListValue1.push(item)
                            }
                        })
                    })
                    this.form.counterUserIdList1 = counterUserIdListValue1;

                    console.log('this.form.counterUserIdList1',this.form.counterUserIdList1)
                
                     let masterCounterUserDeleteFlag=true
                    for(let i=0;i<this.form.counterUserIdList1.length;i++){
                         if(this.form.counterUserIdList1[i].value==this.form.masterCounterUser){
                         masterCounterUserDeleteFlag=false
                        }
                    }

                    if(masterCounterUserDeleteFlag){
                         this.form.masterCounterUser=''
                    }
            },
            onChange(){
            },
            remoteMethod(value) {
                this.pageSize = 99;
                this.pageCurrent = 1;
                this.loading = false;
                this.query = value;
                this.loadMore();
            },
            scolling() {
                let e = this.$refs.select.$refs.scrollbar.$refs.wrap
                if (this.noMore) return
                // 到底时触发 loadMore
                let loadMore = e.scrollHeight - e.scrollTop <= e.clientHeight
                if (loadMore) {
                    this.loadMore()
                }
            },
            loadMore() {
                if (this.loading) {
                    return
                }
                this.getGoodsControllerList();
            },
            // 获取货管员列表
            getGoodsControllerList() {
                // if(!this.query) {
                //     return
                // }
                let dto = {
                    pageSize: this.pageSize,
                    pageNum: this.pageCurrent,
                    // counterId: this.$route.query.id,
                    realName: this.query,
                    isNeed: 1
                };
                let businessFlag = this.getLoginType();
                if (!businessFlag) {
                    dto.merchantId=this.merchantId
                }
                this.loading = true;
                let url = 'mall-service/counterUser/page/query';
                this.post(url, dto, {
                    isUseResponse: true
                }).then(res => {
                    this.loading = false;
                    if (this.pageSize == res.data.total) {
                        // 获取到最后的值时，不再监听滚动条的动作，移除滚动事件
                        this.$refs.select.$refs.scrollbar.$refs.wrap.removeEventListener('scroll', this
                            .scolling())
                        this.noMore = true
                    } else {
                        this.noMore = false;
                    }
                    this.pageSize = this.pageSize + 10;
                    res.data.list = JSON.parse(JSON.stringify(res.data.list).replace(/realName/g, "label"));
                    res.data.list = JSON.parse(JSON.stringify(res.data.list).replace(/id/g, "value"));

                    this.realNameList = [];
                    res.data.list.forEach(item => {
                        if (item.state == 1) {
                            this.realNameList.push(item)
                        }
                    })
                    let counterUserIdListValue = []
                    let counterUserIdListValue1 = []
                    this.realNameList.forEach(item => {
                        this.counterUserIdList.forEach(val => {
                            if (item.value == val) {
                                counterUserIdListValue.push(val)
                            }
                        })
                    })
                    this.realNameList.forEach(item => {
                        this.counterUserIdList.forEach(val => {
                            if (item.value == val && item.type != 1) {
                                counterUserIdListValue1.push(item)
                            }
                        })
                    })
                    this.form.masterCounterUser = this.realNameId;
                    this.form.counterUserIdList = counterUserIdListValue;
                    this.form.counterUserIdList1 = counterUserIdListValue1;
                    console.log('realNameList:', this.realNameList);
                    console.log('form.counterUserIdList1', this.form.counterUserIdList1)
                }).catch(err => {
                    this.loading = false;
                })
            },
            // 点击升级固件
            clickUpgrade() {
                console.log('点击升级固件')
                this.dialogVisibleUpdate = true;
                this.passwordVal = '';
            },
            closeFun() {
                this.dialogVisibleUpdate = false;
            },
            sureBtn() {
                if (!this.passwordVal) {
                    return this.$message({
                        message: '请输入密码',
                        type: 'warning'
                    })
                } else if (this.passwordVal != password) {
                    return this.$message({
                        message: '密码错误',
                        type: 'warning'
                    })
                }
                this.dialogVisibleUpdate = false;
                cabinetUtil.upgrade(this.upgradeDto);
            },
            getTypeList() {
                this.post('mall-service/v1/door/channel/type?counterId=' + this.$route.query.id, {}, {
                    isUseResponse: true,
                }).then(res => {
                    if (res.data.code == 0) {
                        this.freightTrackList = res.data.data || [];
                    }
                })
            },
        }
    };
</script>
<style lang="scss" scoped>
    .containerDeviceDetails {
        margin: auto;
        width: 800px;

        .content-box {
            margin: 20px 0;
            font-size: 15px;

            .title,
            .shoppingCodeTitle,
            .containerPicTitle {
                display: inline-block;
                padding-right: 40px;
                text-align: right;
                width: 200px;
                color: #303133;
            }

            .title1 {
                display: inline-block;
                padding-left: 40px;
                padding-right: 10px;
                text-align: right;
                color: #303133;
            }

            .shoppingCodeTitle {
                padding-right: 0;
            }

            .accessCodeTitle {
                width: 80px;
            }

            .content {
                color: #666;
            }

            .locationContent {
                display: inline-block;
                width: 200px;
                color: #666;
                word-break: break-all;
            }

            .editClass {
                display: inline-block;
                color: #30BAC1;
                margin-left: 40px;
                cursor: pointer;
            }

            .shoppingCode {
                display: inline-block;
                width: 252;
                height: 252px;
            }

            .containerPic {
                width: 151px;
                height: 151px;
            }


        }

        .editBox {
            display: inline-block;
            margin: auto;

            .editTitle {
                display: inline-block;
                padding-right: 10px;
                text-align: right;
                width: 101px;
                color: #606266
            }

            .editContent {
                margin: 10px;
            }

            .el-textarea {
                width: 53%;
            }
        }

        .deviceDetails-check-title {
            padding-bottom: 10px;
            font-weight: 700;
        }

        .deviceDetails-check-text {
            padding-bottom: 10px;
            display: flex;

            span {
                padding-right: 46px;
            }
        }

        .deviceDetails-table {
            display: flex;
        }

        .table-box {
            height: 400px;
            overflow-y: auto;
        }

        .goodsControllerDialog {
            .el-dialog {
                margin-top: 15vh !important;
                width: 540px;
            }
        }

        .dialog_content {
            text-align: center;
            padding-bottom: 40px;
        }

        .dialog_bottom {
            text-align: center;
        }
    }

    .containerDeviceDetails {
        .el-dialog {
            // margin-top: 28vh !important;
            // width: 562px;
        }

        .el-dialog__footer {
            text-align: center;
        }

        .el-dialog__header {
            text-align: center;
            font-weight: bold;
        }
    }
</style>